<template>
  <div class="sub-div">
      <div class="left">
        <Tree :data="treeData" :multiple="false" @on-select-change="selectTree"></Tree>
      </div>
      <div class="center">
        <div class="search-div" style="height:30px;line-height:30px;margin:10px 0 10px 5px;display:flex ">
          <Cascader @on-change="changeCasder" :load-data="loadData" change-on-select style="margin-right: 10px; width: 300px" :data="CascaderList" v-model="search.scopeCodes" placeholder="请选择区/街道/社区"></Cascader>
          <Input style="width:150px" v-model.trim="search.nameOrCode" placeholder="请输入机构名称或编码"/>
          <Button type="primary" style="margin-left:10px" @click="searchBtn">搜索</Button>
          <Button type="info" style="margin-left:10px" @click="resetBtn">重置</Button>
        </div>
        <Table :loading="tableLoading" @on-select="onSelect"  @on-select-all="onSelectAll"
                  @on-select-all-cancel="onSelectAllCancel" @on-select-cancel="onSelectCancel" :columns="talbeColumns" :data="tableData"  height="650" stripe v-if="showTable"></Table>
            <Page
               style="float: right; margin: 10px"
               @on-change="morePageFn"
               :current="page"
               @on-page-size-change="PageSizeChange"
               :page-size="pageSize"
               :total="total"
               :page-size-opts="[20, 50, 100, 200]"
               size="small"
               show-total
               show-elevator
               show-sizer
                />
      </div>
      <div class="btn">
          <div class="btn-div">
            <Button type="error" @click="deleteAll">清空</Button>
          </div>
      </div>
      <div class="right">
            <div class="top-div">已选择的组织</div>
            <div class="none" v-if="rightList && rightList.length == 0" style="color:#515a6e;text-align:center;margin-top:20px">暂无数据</div>
            <div class="item" v-else v-for="(item,index) in rightList" :key="index">
              <div style="width:90%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.orgName}}</div>
              <div style="margin-left:auto;width:10%;margin-right:10px"><Icon class="icon" type="ios-close" @click="deleteRow(item)" /></div>
              </div>
      </div>
  </div>
</template>

<script>
export default {
  props:['showNumber','rightList'],
  data(){
    return{
      treeData:[],

      //分页接口
      page:1,
      pageSize:20,
      tableLoading:false,

      // 选择的树节点id
      dimensionId:"",
      // 中间table表格数据
      tableData:[],
      talbeColumns:[
        {
          type:'selection',
          width:80,
          align:'center'
        },
         {
          title:'组织名称',
          key:'orgName',
          minWidth:200,
          align:'center'
        },
        {
          title: '组织机构编码',
          key: 'orgCode',
          minWidth: 100,
          align: "center",
        },
        // {
        //   title: '所属组织维度',
        //   key: 'dimensionName',
        //   minWidth: 150,
        //   align: "center",
        // },
      ],
      total:0,
      page:1,
      pageSize:20,
      showTable:true,


      search:{
        scopeCodes:[],
        scopeType:'',
        nameOrCode:'' //通过机构名称或编码查询
      },

      CascaderList:[] // 存储的区街道社区
    }
  },
  methods:{
    // 搜索按钮
    searchBtn(){
      if(this.dimensionId == ''){
        this.search = {
          scopeCodes:[],
          scopeType:'',
          nameOrCode:''
        }
        this.$Message.warning({
          content:'请先点击左边的维度后再进行搜索',
          background:true
        })
        return
      }
      if(this.search.scopeCodes.length != 0){
        if(this.search.scopeCodes.length == 1){
          this.search.scopeType = 1
        }else if(this.search.scopeCodes.length == 2){
          this.search.scopeType = 2
        }else if(this.search.scopeCodes.length == 3){
          this.search.scopeType = 3
        }
          this.search.scopeCodes = this.search.scopeCodes[this.search.scopeCodes.length -1]
      }else if(this.search.scopeCodes.length == 0){
        this.search.scopeCodes = ''
        this.search.scopeType = ''
      }
      this.getList()
    },
    // 重置部分
    resetBtn(){
      this.search={
        scopeCodes:[],
        scopeType:'',
        nameOrCode:'' //通过机构名称或编码查询
      }
      this.getList()
    },
    // 方法部分
    deleteAll(){
      if(this.rightList && this.rightList.length == 0){
        this.$Message.warning({
          content:'暂无可清空的数据',
          background:true
        })
        return
      }
      this.$emit('deleteAll')
      this.getList()
    },
    deleteRow(row){
      this.rightList.map((item,index) =>{
        if(item.orgId == row.orgId){
          this.rightList.splice(index,1)
        }
      })
      

     this.getList()

      
      // this.showTable = false
      // this.$nextTick(()=>{
      //   this.showTable = true
      // })

      console.log('tableData',this.tableData);
      this.$emit("handleSelect", this.rightList);
    },
    // ---------------------------分页记忆---------------------------
    /**
     * @param {*} selection 已选项数据
     * @param {*} row 刚选择的项数据
     * @description 勾选table某一项时触发
     */
    onSelect(selection, row) {
      this.rightList.push(row); // 将该条数据添加到tempArr中
      this.tableData.forEach(item => {
        // 本页数据中找到该条勾选数据并添加属性_checked = true
        if (item.orgId === row.orgId) {
          item["_checked"] = true;
        }
      });
      this.$emit("handleSelect", this.rightList);
    },

    /**
     * @param {*} selection 已选项数据
     * @param {*} row 刚取消选择的项数据
     * @description 取消table某一项时触发
     */
    onSelectCancel(selection, row) {
      this.rightList.forEach((item, index) => {
        // tempArr中找到该条数据并删除
        if (row.orgId === item.orgId) {
          this.rightList.splice(index, 1);
        }
      });
      this.$emit("handleSelect", this.rightList);
    },

    /**
     * @param {*} selection 已选项数据
     * @description table全选时触发
     */
    onSelectAll(selection) {
      // this.sortSelect = [];
      selection.forEach(item => {
        this.rightList.push(item);
      });

      this.rightList = this.$core.onlyList(this.rightList, "orgId")// 去重
      this.$emit("handleSelect", this.rightList);
    },

    /**
     * @param {*} selection 已选项数据
     * @description table取消全选时触发
     */
    onSelectAllCancel() {
      this.rightList = this.qufei(this.tableData,this.rightList)
      this.$emit("handleSelect", this.rightList);
    },
    qufei(array1,array2){
        let result = []
        for(var i = 0; i < array2.length; i++){
            var obj = array2[i];
            var num = obj.orgId;
            var isExist = false;
            for(var j = 0; j < array1.length; j++){
                var aj = array1[j];
                var n = aj.orgId;
                if(n == num){
                    isExist = true;
                    break;
                }
            }
            if(!isExist){
                result.push(obj);
            }
        }
        return result
    },
     //  改变了页的条数
       PageSizeChange(val){
           this.pageSize = val
           this.getList()
       },
    //  改变了第几页
       morePageFn(val){
           this.page = val
           this.getList()
       },
    // 点击了树节点回调事件
    selectTree(val){
      this.dimensionId = val[0].dimensionId
      this.page = 1
      this.search = {
        scopeCodes:[],
        scopeType:'',
        nameOrCode:''
      }
      this.getList(this.dimensionId)
    },
    // 获取中间table表格数据
    getList(){
      this.tableLoading = true
      this.$get('/gx/pc/organization/queryOrgPageByDimensionId',{
        page:this.page,
        pageSize:this.pageSize,
        dimensionId:this.dimensionId,
        oemCode:parent.vue.oemInfo.oemCode,
        ...this.search
      }).then(res=>{
        this.tableLoading = false
        if(res.code == 200){
          let data = []
          if(this.rightList.length != 0){
              this.rightList.map(item=>{
                data.push(item.orgId)
              })
          }
          if(res.dataList && res.dataList.length != 0){
            res.dataList.map(item =>{
              if(data.length != 0){
                if(data.join(',').indexOf(item.orgId) > -1){
                  item._checked = true
                }else{
                  item._checked = false
                }
              }
              
            })
          }
          this.tableData = res.dataList
          this.total = res.maxCount
        }else{
          this.$Message.error({
            content:'获取组织机构数据失败',
            background:true
          })
          return
        }
      })
    },
    // 获取左边tree结构的接口
    getTree(){
      this.$get('/gx/pc/dimension/selectByOemCode',{
        oemCode:parent.vue.oemInfo.oemCode,
      }).then(res=>{
        if(res.code == 200){
          if(res.dataList && res.dataList.length != 0){
            res.dataList.map(item =>{
              this.treeData.push({
                title:item.name,
                dimensionId:item.id
              })
            })
          }
        }else{
          this.$Message.error({
            content:'获取维度失败',
            background:true
          })
          return

        }
      })
    },
     // 获取级联
    getCascader(){
      this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:'4401',
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           this.CascaderList = this.forMatterTree(res.dataList)
           this.CascaderList.map(item =>{
             item.loading = false
           })
         }
      })
    },
    // 获取下级机构formattertree结构
    forMatterTree(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].orgName;
        data[i].value = data[i].orgCode;
         if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
              this.forMatterTree(data[i].children);
         }
      }
      return data;
    },
    // 点击加载下级
    loadData (item, callback) {
      item.loading = true
      let params = {
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:item.orgCode,
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      };
       this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        ...params
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           let arr = this.forMatterTree(res.dataList)
           arr.map(items =>{
             if(items.orgLevel == 4){
               items.loading = false
             }
           })
           item.loading = false
           item.children = arr
           callback(item.children);
         }else{
           callback([]);
         }
      })
    },
  },
  watch:{
    showNumber(val){
      this.treeData = []
      this.page = 1
      this.pageSize = 20
      this.tableData = []
      this.getTree()
      this.getCascader()
    }
  }

}
</script>

<style lang="less" scoped>
.sub-div{
  width: 100%;
  height: 750px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: flex-start;
  .left{
    width: 10%;
    height: 100%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    overflow-y: scroll;
  }
  .center{
    width: 70%;
    height: 100%;
    border-right: 1px solid #ccc;
  }
  .btn{
    width: 5%;
    height: 100%;
    border-right: 1px solid #ccc;
    &-div{
      height: 200px;
      margin-top: 325px;
      text-align: center;
    }
  }
  .right{
    width: 15%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    padding-top: 40px;
    .top-div{
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: #f8f8f9;
      color: #515a6e;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-weight: bold;
    }
    .item{
      height: 20px;
      line-height: 20px;
      margin: 5px 10px;
      width: 100%;
      display: flex;
      .icon{
        background: #ccc;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        &:hover{
          background: rgb(148, 148, 148);
        }
      }
    }
  }
}
</style>